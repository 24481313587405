import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { FaSpinner } from "react-icons/fa"

import SEO from "../components/seo"

import "../styles/global.css"

const IndexPage = () => {
  useEffect(() => {
    const lang = navigator.language
    const langGeneric = lang.split("-")[0]
    if (langGeneric === "pt") {
      navigate("/pt/")
    } else if (langGeneric === "es") {
      navigate("/es/")
    } else {
      navigate("/en/")
    }
  }, [])
  return (
    <div className="loading">
      <SEO />
      <FaSpinner />
    </div>
  )
}

export default IndexPage
